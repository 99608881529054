/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.info-box {
  display: grid;
  grid-template-columns: 50% 50%;
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
}
.info-box .info-item {
  display: flex;
  border: 1px solid #ccc;
  border-right: 0;
  border-bottom: 0;
  height: 40px;
}
.info-box .info-item .info-title {
  flex: 200px 0 0;
  border-right: 1px solid #ccc;
  background: #e5ebff;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;
}
.info-box .info-item .info-content {
  padding-left: 10px;
  flex: 1;
  display: flex;
  align-items: center;
}
